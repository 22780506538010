var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "submit-button-text": _vm.$t("save"),
        "supports-editor": false
      },
      on: {
        submit: function($event) {
          return _vm.edit()
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("filenamePreview")))]),
        _c("h4", { staticClass: "titlePreview" }, [
          _vm._v(
            " " + _vm._s(_vm.item.filename) + "." + _vm._s(_vm.item.type) + " "
          )
        ])
      ]),
      _c("div", { attrs: { slot: "middle" }, slot: "middle" }, [
        _vm.item && _vm.item.type !== "pdf" && _vm.item.photo
          ? _c(
              "div",
              { staticClass: "photoContainer" },
              [
                _c("BaseImage", { attrs: { source: _vm.item, size: "medium" } })
              ],
              1
            )
          : _vm._e(),
        _vm.item.type === "pdf"
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("BaseIcon", {
                  attrs: {
                    "icons-group": "far",
                    icon: "fa-file-pdf",
                    size: "xxl"
                  }
                }),
                _c("br"),
                _c("br")
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("altTitle"),
              placeholder: _vm.$t("enterAltTitle")
            },
            model: {
              value: _vm.item.title,
              callback: function($$v) {
                _vm.$set(_vm.item, "title", $$v)
              },
              expression: "item.title"
            }
          }),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("caption"),
              placeholder: _vm.$t("enterPhotoCaption")
            },
            model: {
              value: _vm.item.caption,
              callback: function($$v) {
                _vm.$set(_vm.item, "caption", $$v)
              },
              expression: "item.caption"
            }
          }),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("renameFileExcludeType"),
              placeholder: _vm.$t("enterNewNameWithoutType")
            },
            model: {
              value: _vm.item.filename,
              callback: function($$v) {
                _vm.$set(_vm.item, "filename", $$v)
              },
              expression: "item.filename"
            }
          }),
          _c("BaseInput", {
            attrs: {
              value: "" + _vm.item.type,
              label: _vm.$tc("type"),
              disabled: ""
            }
          }),
          _c("BaseInput", {
            attrs: {
              value: _vm.item.width + " px",
              label: _vm.$t("width"),
              disabled: ""
            }
          }),
          _c("BaseInput", {
            attrs: {
              value: _vm.item.height + " px",
              label: _vm.$t("height"),
              disabled: ""
            }
          }),
          _c("BaseInput", {
            attrs: {
              value:
                "" +
                (_vm.item.size >= 1000
                  ? _vm.convertBytes(_vm.item.size)
                  : _vm.item.size + " Bytes"),
              label: _vm.$t("fileSize"),
              disabled: ""
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }